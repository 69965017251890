.blogCard{
  &-plus {
    width: 42px;
    height: 42px;
  }
  &-img {
    width: 100%;
    aspect-ratio: 1/0.8;
    overflow: hidden;
    position: relative;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &-overlay {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($darkColor, 0.6);
    }
    &:hover .blogCard-img-overlay{
      display: flex;
    }
  }
    &-date-name {
      background-color: rgba($darkColor, 0.05);
    }
}
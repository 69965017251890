.donateContent{
  &-amount{
    color: $darkColor;
    background-color: rgba($darkColor, 0.03);
    padding: 6px 14px;
  }

  &-personal-info{
    &-grid {
      display: grid;
      grid-template-columns: 1fr;
      gap: 24px;
      
      @include bootstrapDevice(md) {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}
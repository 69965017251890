.coming-soon{
  width: 100%;
  min-height: 100vh;
  
  &-content {
    min-height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background: url("../../images/background/Soon-Background.png");
  }

  &-img{
    height: 100vh;
    overflow: hidden;

    img {
      object-fit: cover;
    }
  }
}
.herosection {
  background-image: linear-gradient(rgba($blackColor, 0.7), rgba($blackColor, 0.7)), url("../../images/background/hero-background.jpg");
  width: 100%;
  padding: 180px 10px 40px 10px;
  display: flex;
  flex-direction: column;
  gap: 120px;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    top: 330.76px;
    left: -535.29px;
    width: 975.11px;
    height: 307.52px;
    background: rgba($primaryColor, 0.4);
    z-index: -1;
    transform: rotate(142.74deg);
    border-radius: 200px;
    -webkit-border-radius: 200px;
    -moz-border-radius: 200px;
    -ms-border-radius: 200px;
    -o-border-radius: 200px;
    -webkit-transform: rotate(142.74deg);
    -moz-transform: rotate(142.74deg);
    -ms-transform: rotate(142.74deg);
    -o-transform: rotate(142.74deg);
  }
  @include bootstrapDevice(lg) {
    &::before {
      content: "";
      position: absolute;
      top: 230.76px;
      right: -590.29px;
      width: 975.11px;
      height: 197.95px;
      background: rgba($primaryColor, 0.4);
      z-index: -1;
      transform: rotate(142.74deg);
      border-radius: 200px;
      -webkit-border-radius: 200px;
      -moz-border-radius: 200px;
      -ms-border-radius: 200px;
      -o-border-radius: 200px;
      -webkit-transform: rotate(142.74deg);
      -moz-transform: rotate(142.74deg);
      -ms-transform: rotate(142.74deg);
      -o-transform: rotate(142.74deg);
    }
  }


  &-overlay {
    width: 100%;
    height: 100%;
  }
}
.btn-theme-primary-animated {
  background: $primaryColor;
  padding: 16px 28px;
  color: $secondaryColor;
  white-space: nowrap;
  position: relative;
  border-radius: 50px;
  outline: none;
  border: none;
  z-index: 99;
  transition: 0.3s;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;

  & > .btn-dot {
    position: absolute;
    z-index: -11;
    top: 0;
    left: 0;
    width: 55px;
    height: 100%;
    background-color: $lightColor;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
}

  &:hover .btn-dot {
  width: 100%;
  height: 100%;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

  & > .btn-txt {
    z-index: 44;
  white-space: nowrap;
  }
}
.btn-theme-secondary-animated {
  background: $darkColor;
  padding: 16px 28px;
  color: $lightColor;
  position: relative;
  border-radius: 50px;
  outline: none;
  border: none;
  z-index: 99;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;

  & > .btn-dot {
    position: absolute;
    z-index: -11;
    top: 0;
    left: 0;
    width: 55px;
    height: 100%;
    background-color: $primaryColor;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out;
}

  &:hover {
    color: $darkColor;

    & .btn-dot {
        width: 100%;
        height: 100%;
        border-radius: 50px;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        -ms-border-radius: 50px;
        -o-border-radius: 50px;
      }
  }

  & > .btn-txt {
    z-index: 44;
  white-space: nowrap;
  }
}

.btn-theme-primary-filled {
  background: $primaryColor;
  padding: 16px 28px;
  color: $secondaryColor;
  position: relative;
  border-radius: 50px;
  outline: none;
  border: none;
  z-index: 99;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;

  & > .btn-dot {
    display: none;
  }

  &:hover {
    background: $lightColor;
    color: $secondaryColor;
  }
}
.btn-theme-secondary-filled {
  background: $lightColor;
  padding: 16px 28px;
  color: $secondaryColor;
  position: relative;
  border-radius: 50px;
  transition: 0.3s;
  outline: none;
  border: none;
  z-index: 99;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  &>.btn-dot {
    display: none;
  }

  &:hover {
    background: $primaryColor;
    color: $secondaryColor;
  }
}
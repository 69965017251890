.videobanner{
  background-image: linear-gradient(rgba($blackColor, 0.7), rgba($blackColor, 0.7)), url("../../images/background/video-bg.jpg");
  display: flex;
  flex-direction: column;
  gap: 120px;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  
  &-play {
    position: relative;

    &-shape{
      position: absolute;
      top: 0;
      right: 70px;
    }
  }

  &-play-icon {
      background-color: $primaryColor;
      color: $darkColor;
      padding: 30px;
      position: relative;
      z-index: 1;
      &::before
      {
        content: "";
        z-index: -1;
        background-color: $primaryColor;
        position: absolute;
        transform: scale(1.3);
        opacity: 50%;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -webkit-transform: scale(1.3);
        -moz-transform: scale(1.3);
        -ms-transform: scale(1.3);
        -o-transform: scale(1.3);
}
    }
}
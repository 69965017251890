.subpage-banner{
  background: linear-gradient(rgba($darkColor, 0.7), rgba($darkColor, 0.7)), url("../../images/background/Sub-Page-Hero-Background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
  z-index: 1;
  &-overlay {
    &::after {
      content: "";
      position: absolute;
      bottom: -50px;
      left: -370px;
      width: 630.21px;
      height: 198.75px;
      background: rgba($primaryColor, 0.4);
      z-index: 0;
      transform: rotate(142.74deg);
      mix-blend-mode: overlay;
      border-radius: 200px;
      -webkit-border-radius: 200px;
      -moz-border-radius: 200px;
      -ms-border-radius: 200px;
      -o-border-radius: 200px;
      -webkit-transform: rotate(142.74deg);
      -moz-transform: rotate(142.74deg);
      -ms-transform: rotate(142.74deg);
      -o-transform: rotate(142.74deg);
    }
    
    &::before {
      content: "";
      position: absolute;
      top: -294px;
      right: -743px;
      mix-blend-mode: overlay;
      width: 975.11px;
      height: 197.95px;
      background: rgba($primaryColor, 0.4);
      z-index: 0;
      transform: rotate(142.74deg);
      border-radius: 200px;
      -webkit-border-radius: 200px;
      -moz-border-radius: 200px;
      -ms-border-radius: 200px;
      -o-border-radius: 200px;
      -webkit-transform: rotate(142.74deg);
      -moz-transform: rotate(142.74deg);
      -ms-transform: rotate(142.74deg);
      -o-transform: rotate(142.74deg);
    }
  }

  &-content{
    z-index: 22;
  }
}
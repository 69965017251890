@import url('https://fonts.googleapis.com/css2?family=Fraunces:wght@300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
}

.wrapper{
  overflow-x: hidden;
}

* {
  font-family: 'Poppins', sans-serif;
}

.container-theme {
  max-width: 1920px;
  @include mx-auto;
  @include padding-x(230px, 230px);

  @include devices(xxl) {
    @include padding-x(80px, 80px);
  }

  @include devices(xl) {
    @include padding-x(80px, 80px);
  }
  
  @include devices(lg) {
    @include padding-x(80px, 80px);
  }
  
  @include devices(md) {
    @include padding-x(60px, 60px);
  }
  
  @include devices(sm) {
    @include padding-x(20px, 20px);
  }
  
  @include devices(xs) {
    @include padding-x(20px, 20px);
  }
}

a {
  text-decoration: none !important;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Fraunces',
    Georgia,
    "Times New Roman",
    serif;
  margin: 0 !important;
}

.ff-head{
  font-family: 'Fraunces',
      Georgia,
      "Times New Roman",
      serif;
}

.input{
  border: none;
  outline:none;
  border-bottom: 1px solid $grayColor;
  padding: 8px 0px;
}

.list-circle{
  background-color: $darkColor;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

h1 {
  font-size: 70px !important;
  font-weight: 700 !important;

  @include devices(md) {
    font-size: 60px !important;
      font-weight: 700 !important;
  }

  @include devices(sm) {
    font-size: 50px !important;
      font-weight: 700 !important;
  }

  @include devices(xs) {
    font-size: 40px !important;
      font-weight: 700 !important;
  }
}
h2 {
  font-size: 60px !important;
  font-weight: 700 !important;

  @include devices(md) {
    font-size: 50px !important;
      font-weight: 700 !important;
  }

  @include devices(sm) {
    font-size: 40px !important;
      font-weight: 700 !important;
  }

  @include devices(xs) {
    font-size: 30px !important;
      font-weight: 700 !important;
  }
}

h3 {
  font-size: 50px !important;
  font-weight: 700 !important;

  @include devices(md) {
    font-size: 40px !important;
      font-weight: 700 !important;
  }

  @include devices(sm) {
    font-size: 35px !important;
      font-weight: 700 !important;
  }

  @include devices(xs) {
    font-size: 30px !important;
      font-weight: 700 !important;
  }
}

.divider-dot{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $primaryColor;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

p{
  margin: 0 !important;
}
.section-padding{
  padding-top: 100px;
  padding-bottom: 100px;
}
.bg-theme-primary {
  background: $primaryColor;
  background-color: $primaryColor !important;
}
.bg-theme-secondary {
  background: $secondaryColor;
  background-color: $secondaryColor !important;
}
.bg-theme-white{
  background: $lightColor;
  background-color: $lightColor !important;
}
.bg-theme-light-gray{
  background: rgba($darkColor, 0.03);
  background-color: rgba($darkColor, 0.03) !important;
}
.bg-theme-dark{
  background: $darkColor;
  background-color: $darkColor !important;
}
.bg-theme-gray{
  background: $grayColor;
  background-color: $grayColor !important;
}
.text-theme-primary {
  color: $primaryColor !important;
}
.text-theme-secondary {
  color: $secondaryColor !important;
}
.text-theme-white{
  color: $lightColor !important;
}
.text-theme-dark {
  color: $darkColor !important;
}
.text-theme-gray{
  color: $grayColor !important;
}
.border-theme-primary {
  border: 1px solid $primaryColor !important;
}
.border-theme-primary-opacity-50 {
  border: 1px solid rgba($primaryColor, 0.3) !important;
}
.border-theme-secondary {
  border: 1px solid $secondaryColor !important;
}
.border-theme-white{
  border: 1px solid $lightColor !important;
}
.border-theme-dark {
  border: 1px solid $darkColor !important;
}
.border-theme-gray{
  border: 1px solid $grayColor !important;
}
.border-theme-light-gray {
  border: 1px solid rgba($darkColor, 0.2) !important;
}
.divider {
  width: 100%;
  height: 1px;
  background-color: rgba($darkColor, 0.2);
}
.breadcrumb-item+.breadcrumb-item::before{
  content: var(--bs-breadcrumb-divider, " || ") !important;
  color: $lightColor !important;
}
.whyus {
  background: url("../../images/shape/why-choose-us-background.png");
  &-right {
    position: relative;
  }
  &-right-experience-circle {
    position: absolute;
    bottom: 0;
    border-top: 1px solid $lightColor;
    border-left: 1px solid $lightColor;
    border-right: 1px solid $lightColor;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    min-width: 140px;
    min-height: 140px;
    
    &-icon {
      position: absolute;
      top: -40%;
      transform: translateY(50%);
      -webkit-transform: translateY(50%);
      -moz-transform: translateY(50%);
      -ms-transform: translateY(50%);
      -o-transform: translateY(50%);
}
  }
}
.project-section{
  position: relative;
  &-top-banner{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: url("../../images/shape/projects-background.png");
    background-color: rgba($darkColor, 0.03);
  }
}
.cause-detail-section {
  &-img {
    aspect-ratio: 1/0.7;
    width: 100%;
    overflow: hidden;

    & img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.theme-progress{
  height: 5px;
  position: relative;
  &-active {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 30%;
  }
}
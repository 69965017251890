.feedback-section{
  position: relative;
  z-index: 1;
    &-top-banner {
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background: url("../../images/shape/testimonial-bg.png");
      background-color: $darkColor;
      background-repeat: no-repeat;
    }
}
.header-logo{
  width: 250px;
}

.sidebar-logo{
  width: 200px;
}

.header {
  background: url("../../images/background/header-bg.png");
  background-position: center;
  background-repeat: no-repeat;
}

.activeNav{
  border-top: 3px solid $primaryColor;
}
.header-link{
  border-top: 3px solid transparent;
  &:hover{
    border-top: 3px solid $primaryColor;
  }
}

.header-menu {
  box-sizing: border-box;
  border-top: 1px solid;
  border-top-color: rgba($lightColor, 0.1) !important;
}

.theme-dropdown{
  z-index: 999;
  box-sizing: border-box;
  border-top: 3px solid transparent;
  &:hover {
    border-top: 3px solid $primaryColor;
  }
  &-menu{
    border-top: 4px solid $primaryColor;
    min-width: 200px;
    display: none;
  }
  &:hover > &-menu{
    display: flex;
    position: absolute;
    top: 100%;
  }
  &-link:hover{
    background-color: $secondaryColor;
    color: $lightColor;
    a {
        color: $lightColor
      }
  }
  a{
    color: $secondaryColor
  }
}

.btn-close {
  --bs-btn-close-bg: url("../../images/icons/close.svg") !important;
  opacity: 1 !important;
}

.sidebar-dropdown{
  width: 100%;
  color: $lightColor !important;
  
  & .accordion-item {
    color: $lightColor !important;
    background-color: transparent !important;
    border: none !important;
  }

   & .accordion-button:not(.collapsed){
      box-shadow: none;
    }

      &  .accordion-button::after {
        background-image: url("../../images/icons/arrow-down.svg")
      }

      & .accordion-button:not(.collapsed)::after{
      background-image: url("../../images/icons/arrow-down.svg")
    }

  & button {
  font-family: 'Poppins', sans-serif !important;
  outline: none !important;
  }

  & .accordion-button{
    color: $lightColor !important;
    background-color: transparent !important; 
    background: transparent !important;
    padding: 0 !important; 
  }

  & .theme-dropdown-link a {
    color: $lightColor !important;

  }
}

.offcanvas-body {
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
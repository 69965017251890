.about {
  &-right-experience-circle {
    min-width: 140px;
    min-height: 140px;
    margin-top: -200px;
  }

  &-list-icon{
    background-color: $primaryColor;
    padding: 10px;
    border-radius: 50%;
    position: relative;
    z-index: 1;
    &::before {
      content: "";
      z-index: -1;
      background-color: $primaryColor;
      position: absolute;
      transform: scale(1.3);
      opacity: 50%;
      border-radius: 50%;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
      -webkit-transform: scale(1.3);
      -moz-transform: scale(1.3);
      -ms-transform: scale(1.3);
      -o-transform: scale(1.3);
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
}
  }

  &-right{
    position: relative;

    & .shape-right {
      position: absolute;
      top: -40px;
      right: 0;
    }
    & .shape-left {
      position: absolute;
      top: -20px;
      left: 20px;
    }
  }
} 